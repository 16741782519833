var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-3/4 mx-auto"},[_c('p',{staticClass:"text-primary text-xl font-semibold"},[_vm._v("Capture vitals")]),(_vm.confirmed)?_c('ConfirmVitalsModal',{attrs:{"loading":_vm.loading,"form":_vm.form},on:{"save":_vm.save,"back":function($event){_vm.confirmed = false}}}):_c('cv-form',{staticClass:"grid grid-cols-4 my-6 gap-6",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('cv-radio-group',{attrs:{"vertical":true}},_vm._l((_vm.navItems),function(item,index){return _c('div',{key:index,staticClass:"border-l-2  h-20 flex items-center border-solid px-4 cursor-pointer",class:[
            _vm.checked == item.index
              ? 'border-serenity-primary'
              : 'border-serenity-subtle-border' ],attrs:{"tag":"div"},on:{"click":function($event){_vm.checked = item.index}}},[_c('div',{staticClass:"flex"},[_c('div',[(_vm.checked == item.index)?_c('CircleFilled',{staticClass:"w-5 h-5",class:[
                  _vm.checked == item.index
                    ? 'text-serenity-primary'
                    : 'text-primary' ]}):(_vm.checked >= item.index)?_c('CheckmarkOutline',{staticClass:"w-5 h-5 text-serenity-primary"}):_c('div',{staticClass:"w-5 h-5 border border-solid rounded-full"})],1),_c('div',{staticClass:"ml-4"},[_c('p',{staticClass:"leading-4",class:[
                  _vm.checked == item.index
                    ? 'text-serenity-primary'
                    : 'text-primary' ]},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('p',{staticClass:"text-xs text-secondary leading-7"},[_vm._v(" "+_vm._s(item.description)+" ")])])])])}),0)],1),_c('div',{staticClass:"col-span-3 bg-white py-4 px-8"},[_c(_vm.stepComponent,{tag:"component",on:{"next":_vm.nextStep,"confirm":function($event){_vm.confirmed = true}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }